import React from "react";
import * as style from "./Users.style";
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation, Query } from "../../gql";
import { connect, useDispatch } from "react-redux";
import * as Models from "../../models";
import * as Components from "../../components";
import { UserActions } from "../../store/actions";
import { classes } from "typestyle";
import { RootState } from "../../store/reducers";
import { Checkbox, MenuItem, Select } from "@mui/material";
import { toast } from "react-toastify";

interface StateProps {
  users: Record<number, Models.Users.ShakeUser> | null;
}

export const UsersInternal: React.FC<StateProps> = ({ users }) => {
  const client = useApolloClient();
  const dispatch = useDispatch();

  const usersArray = React.useMemo(() => {
    return !!users ? Object.values(users) : [];
  }, [users]);

  const [selectedUser, setSelectedUser] = React.useState<number | null>(null);
  const [displayName, setDisplayName] = React.useState("");
  const [accountStatus, setAccountStatus] = React.useState("");
  const [testingAccount, setTestingAccount] = React.useState(false);
  const [removeImage, setRemoveImage] = React.useState(false);

  const getUsers = () => {
    client
      .query({
        query: Query.GET_USERS_QUERY,
        variables: {
          take: 20,
          skip: usersArray.length,
        },
      })
      .then((res) => {
        if (res?.data?.adminGetUsers?.length) {
          dispatch(UserActions.GetUsersSuccess(res.data.adminGetUsers));
        }
      })
      .catch((res) => {
        console.log("failed", res);
      });
  };

  React.useEffect(() => {
    getUsers();
  }, []);

  const [updateUserMutation, updateUserMutationData] = useMutation(
    Mutation.UPDATE_USER_MUTATION,
  );
  const onSubmit = () => {
    updateUserMutation({
      variables: {
        userId: selectedUser,
        displayName,
        accountStatus,
        testingAccount,
        removeImage,
      },
    })
      .then((res) => {
        if (res.data.adminUpdateUser) {
          dispatch(UserActions.GetUsersSuccess([res.data.adminUpdateUser]));
          setSelectedUser(null);
        }
      })
      .catch((e) => {
        toast.error("Something went wrong updating the User");
      });
  };

  const selectUser = (userId: number) => {
    setSelectedUser(userId);
    if (!!users && !!users[userId]) {
      setDisplayName(users[userId].displayName ?? "");
      setAccountStatus(users[userId].accountStatus ?? "");
      setTestingAccount(users[userId].testingAccount);
      setRemoveImage(false);
    }
  };
  return (
    <div className={style.component}>
      {!!users ? (
        <div className={style.table}>
          <div className={style.row} style={{ fontWeight: 600 }}>
            <div className={style.rowItem}>Id</div>
            <div className={style.rowItem} style={{ flex: 2 }}>
              Email
            </div>
            <div className={style.rowItem}>Display Name</div>
            <div className={style.rowItem}>Profile Img</div>
            <div className={style.rowItem}>Status</div>
            <div className={style.rowItem}>Tester</div>
          </div>
          {usersArray.map((user) => (
            <div
              className={classes(
                style.row,
                user.id === selectedUser ? style.selectedRow : "",
              )}
              key={user.id}
              onClick={() => {
                selectUser(user.id);
              }}
            >
              <div className={style.rowItem}>{user.id}</div>
              <div className={style.rowItem} style={{ flex: 2 }}>
                {user.email}
              </div>
              <div className={style.rowItem}>{user.displayName}</div>
              <div className={style.rowItem}>
                <div className={style.imageContainer}>
                  <img src={user.profileImgUrl} className={style.image} />
                </div>
              </div>
              <div className={style.rowItem}>{user.accountStatus}</div>
              <div className={style.rowItem}>
                {user.testingAccount ? "true" : "false"}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Components.Icon.Spinner size={24} />
      )}
      {!!selectedUser && !!users && !!users[selectedUser] && (
        <div style={{ padding: 24 }}>
          <Components.TextInput
            className={style.textInput}
            value={displayName}
            onChange={setDisplayName}
            label="Display Name"
            autocomplete="off"
          />
          <div className={style.input}>
            <Select
              multiple={false}
              value={accountStatus}
              onChange={(e) => setAccountStatus(e.target.value as string)}
              autoWidth={true}
              label="Reward"
            >
              {accountStatuses.map((status) => (
                <MenuItem value={status} key={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
            <div>Account Status</div>
          </div>
          <div className={style.input}>
            <Checkbox
              value={removeImage}
              onChange={(e) => setRemoveImage(e.target.checked)}
            />
            <div>Remove Image</div>
          </div>
          <div className={style.input}>
            <Checkbox
              value={testingAccount}
              onChange={(e) => setTestingAccount(e.target.checked)}
            />
            <div>Testing Account</div>
          </div>
          <Components.Button
            label="Update User"
            onClick={onSubmit}
            disabled={
              removeImage === false &&
              testingAccount === users[selectedUser].testingAccount &&
              displayName === users[selectedUser].displayName &&
              accountStatus === users[selectedUser].accountStatus
            }
          />
        </div>
      )}
    </div>
  );
};

export const Users = connect((state: RootState) => ({
  users: state.users.users.items,
}))(UsersInternal);

const accountStatuses = ["OPEN", "CLOSED", "LOCKED", "SUSPENDED"];
