import React from "react";
import * as style from "./ViewRewards.style";
import { RootState } from "../../../../store/reducers";
import { connect } from "react-redux";
import * as Models from "../../../../models";
import * as Components from "../../../../components";

interface StateProps {
  rewards: Record<number, Models.Rewards.Reward> | null;
}

interface ComponentProps {
  rewardIds: number[];
  createReward: () => void;
}

export const ViewRewardsInternal: React.FC<StateProps & ComponentProps> = ({
  rewards,
  rewardIds,
  createReward,
}) => {
  //   const client = useApolloClient();
  //   const dispatch = useDispatch();

  //   const getRewards = (skip?: number) => {
  //     client
  //       .query({
  //         query: Query.GET_BRANDS_QUERY,
  //         variables: {
  //           skip: skip ?? 0,
  //           take: 20,
  //         },
  //       })
  //       .then((res) => {
  //         dispatch(RewardActions.GetBrandsSuccess(res.data.adminGetBrands));
  //       });
  //   };
  //   React.useEffect(() => {
  //     getRewards();
  //   }, []);

  return (
    <div className={style.component}>
      <div className={style.table}>
        {rewardIds.map((rewardId) => {
          const reward = !!rewards ? rewards[rewardId] : null;
          return (
            reward && (
              <div className={style.row} key={reward.id}>
                <div className={style.rowItem}>{reward.name}</div>
                <div className={style.rowItem}>
                  {`$${reward.minAmount} - $${reward.maxAmount}`}
                </div>
              </div>
            )
          );
        })}
      </div>
      <Components.Button label="Create" onClick={createReward} />
    </div>
  );
};

export const ViewRewards = connect((state: RootState) => ({
  rewards: state.rewards.rewards.items,
}))(ViewRewardsInternal);
