import React from "react";
import * as style from "./Prizes.style";
import { classes } from "typestyle";
import { ViewClaimedPrizes } from "./ViewClaimedPrizes";
import { ViewUnclaimedPrizes } from "./ViewUnclaimedPrizes";
import { ViewDeliveredPrizes } from "./ViewDeliveredPrizes";

export const Prizes = () => {
  const [activeTab, setActiveTab] = React.useState("ViewClaimedPrizes");
  return (
    <div className={style.component}>
      <div className={style.title}>{`Prizes`}</div>
      <div className={style.topNav}>
        <div
          className={classes(
            style.topNavItem,
            activeTab == "ViewClaimedPrizes" ? style.activeTopNavItem : "",
          )}
          onClick={() => setActiveTab("ViewClaimedPrizes")}
        >
          Claimed Prizes
        </div>
        <div
          className={classes(
            style.topNavItem,
            activeTab == "ViewUnclaimedPrizes" ? style.activeTopNavItem : "",
          )}
          onClick={() => setActiveTab("ViewUnclaimedPrizes")}
        >
            Unclaimed Prizes
        </div>
        <div
          className={classes(
            style.topNavItem,
            activeTab == "ViewDeliveredPrizes" ? style.activeTopNavItem : "",
          )}
          onClick={() => setActiveTab("ViewDeliveredPrizes")}
        >
          Delivered Prizes
        </div>
      </div>
      {activeTab == "ViewClaimedPrizes" && <ViewClaimedPrizes />}
      {activeTab == "ViewUnclaimedPrizes" && <ViewUnclaimedPrizes />}
      {activeTab == "ViewDeliveredPrizes" && <ViewDeliveredPrizes />}
    </div>
  );
};