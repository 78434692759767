import * as Styles from "../../../styles";
import { style as s } from "typestyle";
export {
  picker,
  input,
  textInput,
  checkboxRow,
} from "../CreateContestTemplate/CreateContestTemplate.style";
export { title } from "../ContestTemplates.style";

export const main = s({
  padding: "12px 24px",
  marginBottom: 48,
  justifyContent: "center",
  border: `1px solid ${Styles.colors.darkTranslucent200}`,
});

export const subtitle = s({
  fontSize: 28,
  fontWeight: 500,
  paddingTop: 24,
  marginBottom: 8,
  textAlign: "center",
});

export const description = s({
  fontSize: 14,
  fontWeight: 300,
  color: Styles.colors.darkTranslucent750,
  textAlign: "center",
  marginBottom: 16,
});

export const button = s({
  margin: "auto",
  marginBottom: 12,
});

export const colorRow = s({
  display: "flex",
  gap: 8,
  alignItems: "center",
  marginBottom: 16,
});

export const colorBox = s({
  height: "100px !important",
  width: "100px !important",
  marginRight: 12,
  $nest: {
    "& .react-colorful__pointer": {
      width: 21,
      height: 21,
    },
  },
});

export const label = s({
  fontSize: 16,
  fontWeight: 500,
});

export const error = s({
  color: Styles.colors.error,
  fontSize: 12,
  marginBottom: 12,
});

export const imageContainer = s({
  height: "200px",
  marginBottom: 15,
});

export const image = s({
  height: "100%",
});

export const removeImageButton = s({
  padding: `4px 8px`,
  fontSize: 12,
  width: "fit-content",
  textAlign: "center",
  background: Styles.colors.darkTranslucent650,
  color: Styles.text.colors.inverse,
  cursor: "pointer",
  transition: ".2s ease-in",
  $nest: {
    "&:hover": {
      backgroundColor: Styles.colors.darkTranslucent500,
    },
  },
  marginBottom: 8,
  borderRadius: 4,
  fontWeight: 500,
});
