import { gql } from "@apollo/client";

export const GET_BRANDS_QUERY = gql`
  query adminGetBrands($take: Int!, $skip: Int!) {
    adminGetBrands(take: $take, skip: $skip) {
      id
      name
      imageUrl
      rewards {
        id
        name
        minAmount
        maxAmount
        brandId
      }
    }
  }
`;
