import { gql } from "@apollo/client";

export const UPDATE_USER_MUTATION = gql`
  mutation adminUpdateUser(
    $userId: Int!
    $displayName: String
    $accountStatus: String
    $removeImage: Boolean
    $testingAccount: Boolean
  ) {
    adminUpdateUser(
      userId: $userId
      displayName: $displayName
      accountStatus: $accountStatus
      removeImage: $removeImage
      testingAccount: $testingAccount
    ) {
      id
      email
      displayName
      accountStatus
      profileImgUrl
      testingAccount
      userProfile {
        adminUpdatedBy
      }
      userDate {
        updatedAtUtc
      }
    }
  }
`;
