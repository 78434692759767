import React from "react";
import * as style from "./Rewards.style";
import { classes } from "typestyle";
import { CreateBrands } from "./CreateBrands";
import { ViewBrands } from "./ViewBrands";

export const Rewards = () => {
  const [activeTab, setActiveTab] = React.useState("Create");
  return (
    <div className={style.component}>
      <div className={style.title}>{`Rewards`}</div>
      <div className={style.topNav}>
        <div
          className={classes(
            style.topNavItem,
            activeTab == "CreateBrand" ? style.activeTopNavItem : "",
          )}
          onClick={() => setActiveTab("CreateBrand")}
        >
          Create Brand
        </div>
        <div
          className={classes(
            style.topNavItem,
            activeTab == "ViewBrands" ? style.activeTopNavItem : "",
          )}
          onClick={() => setActiveTab("ViewBrands")}
        >
          View Brands
        </div>
      </div>
      {activeTab == "CreateBrand" && <CreateBrands />}
      {activeTab == "ViewBrands" && <ViewBrands />}
    </div>
  );
};
