import * as Styles from "../../styles";
import { style as s } from "typestyle";

export const component = s({
  padding: "24px 48px",
});

export const topNav = s({
  width: "100%",
  border: `1px solid black`,
  display: "flex",
  marginBottom: 16,
});

export const topNavItem = s({
  flex: 1,
  textAlign: "center",
  padding: 12,
  borderRight: "1px solid black",
  $nest: {
    "&:last-child": {
      borderRightWidth: 0,
    },
  },
});

export const activeTopNavItem = s({
  backgroundColor: Styles.colors.softGreen,
});

export const title = s({
  textAlign: "center",
  fontSize: 40,
  fontWeight: 500,
});
