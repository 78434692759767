import {style as s } from 'typestyle';

export const app = s({
    minHeight: '100vh',
    backgroundColor: '#fcfcfc',
    display: 'flex',
})

export const pageContainer = s({
    flexGrow: 2,
    paddingLeft: 240,
})