import * as Styles from "../../../styles";
import { style as s } from "typestyle";
export {
  picker,
  input,
  textInput,
  checkboxRow,
} from "../CreateContestTemplate/CreateContestTemplate.style";
export { title } from "../ContestTemplates.style";

export const main = s({
  padding: "12px 24px",
  marginBottom: 48,
  justifyContent: "center",
  border: `1px solid ${Styles.colors.darkTranslucent200}`,
});

export const topNav = s({
  margin: "auto",
  border: `1px solid black`,
  display: "flex",
  justifyContent: "center",
  marginBottom: 16,
  width: "fit-content",
});

export const topNavItem = s({
  flex: 1,
  width: 80,
  textAlign: "center",
  padding: 4,
  borderRight: "1px solid black",
  $nest: {
    "&:last-child": {
      borderRightWidth: 0,
    },
  },
});

export const activeTopNavItem = s({
  backgroundColor: Styles.colors.softGreen,
});

export const button = s({
  margin: "auto",
  marginBottom: 12,
});

export const formWrapper = s({
  display: "flex",
  justifyContent: "space-between",
});

export const formSection = s({
  flex: 1,
  paddingRight: 8,
  marginRight: 8,
  borderRight: `1px dotted ${Styles.colors.darkTranslucent200}`,
  $nest: {
    "&:last-child": {
      borderRightWidth: 0,
    },
  },
});

export const subtitle = s({
  fontSize: 24,
  fontWeight: 500,
  marginBottom: 12,
  textAlign: "center",
});

export const matchupSelectionWrapper = s({
  padding: 12,
  overflowY: "scroll",
  maxHeight: 300,
});

export const matchup = s({
  border: `1px solid ${Styles.colors.darkTranslucent200}`,
  borderRadius: 4,
  marginBottom: 12,
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: 8,
  $nest: {
    "&:hover": {
      backgroundColor: Styles.colors.softYellow,
    },
  },
  cursor: "pointer",
});

export const selectedMatchup = s({
  backgroundColor: Styles.colors.yellow,
  $nest: {
    "&:hover": {
      backgroundColor: Styles.colors.yellow,
    },
  },
});
