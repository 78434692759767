/**
 * State
 */

import { validation } from "../../../utility";

export interface NewMatchupOptionVisualization {
  displayName?: string;
  description?: string;
  initials?: string;
  initialsBackgroundColor?: string;
  initialsTextColor?: string;
  imageUrl?: string;
  newImageInB64?: string;
  errors: Record<string, string>;
}

export interface ExistingMatchupOption {
  matchupOptionId: number;
  existingVisualizationId: number;
  newVisualization?: NewMatchupOptionVisualization;
}

export interface CreateMatchupsState {
  name: string;
  description: string;
  eventStartTime: Date;
  matchupOptions: Record<number, ExistingMatchupOption>;
  errors: Record<string, string>;
  readyToSubmit: boolean;
}

export const initialCreateMatchupsState: CreateMatchupsState = {
  name: "",
  description: "",
  eventStartTime: new Date(),
  matchupOptions: {},
  errors: {},
  readyToSubmit: false,
};

/**
 * Actions
 */

export interface UpdateFieldAction {
  type: "UPDATE_FIELD";
  payload: {
    field: "name" | "description" | "eventStartTime";
    value: string;
  };
}

export interface UpdateVisualizationAction {
  type: "UPDATE_VISUALIZATION";
  payload: {
    matchupOptionId: number;
    field:
      | "description"
      | "displayName"
      | "initials"
      | "initialsBackgroundColor"
      | "initialsTextColor"
      | "imageUrl"
      | "newImageInB64";
    value: string;
  };
}

export interface MatchupOptionClickedAction {
  type: "MATCHUP_OPTION_CLICKED";
  payload: {
    matchupOptionId: number;
    existingVisualizationId: number;
  };
}

export interface MatchupOptionAddVisualizationAction {
  type: "MATCHUP_OPTION_ADD_VISUALIZATION";
  payload: {
    matchupOptionId: number;
  };
}

export interface MatchupOptionRemoveVisualizationAction {
  type: "MATCHUP_OPTION_REMOVE_VISUALIZATION";
  payload: {
    matchupOptionId: number;
  };
}

export interface SubmitAction {
  type: "SUBMIT";
}

export interface SubmitSuccessAction {
  type: "SUBMIT_SUCCESS";
}

export interface SubmitFailureAction {
  type: "SUBMIT_FAILURE";
  payload: {
    error: string;
  };
}

export type CreateMatchupActions =
  | UpdateFieldAction
  | MatchupOptionClickedAction
  | MatchupOptionAddVisualizationAction
  | MatchupOptionRemoveVisualizationAction
  | UpdateVisualizationAction
  | SubmitAction
  | SubmitSuccessAction
  | SubmitFailureAction;

/**
 * Reducer
 */

export const createMatchupsReducer = (
  state: CreateMatchupsState,
  action: CreateMatchupActions,
) => {
  switch (action.type) {
    case "UPDATE_FIELD": {
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    }
    case "MATCHUP_OPTION_CLICKED": {
      const newMatchupOptions = {
        ...state.matchupOptions,
      };
      if (!!newMatchupOptions[action.payload.matchupOptionId]) {
        delete newMatchupOptions[action.payload.matchupOptionId];
      } else {
        newMatchupOptions[action.payload.matchupOptionId] = {
          matchupOptionId: action.payload.matchupOptionId,
          existingVisualizationId: action.payload.existingVisualizationId,
        };
      }
      return {
        ...state,
        matchupOptions: newMatchupOptions,
      };
    }
    case "MATCHUP_OPTION_ADD_VISUALIZATION": {
      if (
        !state.matchupOptions[action.payload.matchupOptionId]?.newVisualization
      ) {
        return {
          ...state,
          matchupOptions: {
            ...state.matchupOptions,
            [action.payload.matchupOptionId]: {
              ...state.matchupOptions[action.payload.matchupOptionId],
              newVisualization: {
                description: "",
                displayName: "",
                initials: "",
                initialsBackgroundColor: "",
                initialsTextColor: "",
                imageUrl: "",
                newImageInB64: "",
                errors: {},
              },
            },
          },
        };
      }
      return state;
    }
    case "MATCHUP_OPTION_REMOVE_VISUALIZATION": {
      return {
        ...state,
        matchupOptions: {
          ...state.matchupOptions,
          [action.payload.matchupOptionId]: {
            matchupOptionId: action.payload.matchupOptionId,
            existingVisualizationId:
              state.matchupOptions[action.payload.matchupOptionId]
                .existingVisualizationId,
          },
        },
      };
    }
    case "UPDATE_VISUALIZATION": {
      let newVisualization: NewMatchupOptionVisualization = {
        errors: {},
      };
      if (
        state.matchupOptions[action.payload.matchupOptionId]?.newVisualization
      ) {
        newVisualization = {
          ...(state.matchupOptions[action.payload.matchupOptionId]
            .newVisualization as NewMatchupOptionVisualization),
        };
      }
      newVisualization[action.payload.field] = action.payload.value;
      return {
        ...state,
        matchupOptions: {
          ...state.matchupOptions,
          [action.payload.matchupOptionId]: {
            ...state.matchupOptions[action.payload.matchupOptionId],
            newVisualization,
          },
        },
      };
    }
    case "SUBMIT": {
      let readyToSubmit = true;
      const newErrors: Record<string, string> = {};
      if (!state.name) {
        newErrors["name"] = "Matchup must have a name";
        readyToSubmit = false;
      }
      if (!state.eventStartTime) {
        newErrors["eventStartTime"] = "Matchup must have event start date";
        readyToSubmit = false;
      } else if (state.eventStartTime < new Date()) {
        newErrors["eventStartTime"] = "Matchup must start in the future";
        readyToSubmit = false;
      }
      const matchupOptionsArray = Object.values(state.matchupOptions);
      const newMatchupOptionsDict = { ...state.matchupOptions };
      if (matchupOptionsArray.length < 2) {
        newErrors["matchupOptions"] = "Matchup must have at least 2 options";
        readyToSubmit = false;
      } else {
        matchupOptionsArray.forEach((opt) => {
          const newOptErrors: Record<string, string> = {};
          if (!!opt.newVisualization) {
            if (
              !!opt.newVisualization.initials &&
              (!opt.newVisualization.initialsBackgroundColor ||
                !opt.newVisualization.initialsTextColor)
            ) {
              newOptErrors["initials"] =
                "If initials are included, must also include background and text colors";
              readyToSubmit = false;
            }
            if (
              !!opt.newVisualization.initialsBackgroundColor &&
              !validation.hexColorRegex.test(
                opt.newVisualization.initialsBackgroundColor,
              )
            ) {
              newOptErrors["initialsBackgroundColor"] =
                "Please enter or select a valid hex color";
              readyToSubmit = false;
            }
            if (
              !!opt.newVisualization.initialsTextColor &&
              !validation.hexColorRegex.test(
                opt.newVisualization.initialsTextColor,
              )
            ) {
              newOptErrors["initialsTextColor"] =
                "Please enter or select a valid hex color";
              readyToSubmit = false;
            }
            newMatchupOptionsDict[opt.matchupOptionId] = {
              ...newMatchupOptionsDict[opt.matchupOptionId],
              newVisualization: {
                ...(newMatchupOptionsDict[opt.matchupOptionId]
                  .newVisualization as NewMatchupOptionVisualization),
                errors: newOptErrors,
              },
            };
          }
        });
      }
      return {
        ...state,
        errors: newErrors,
        matchupOptions: newMatchupOptionsDict,
        readyToSubmit,
      };
    }
    case "SUBMIT_SUCCESS": {
      return initialCreateMatchupsState;
    }
    case "SUBMIT_FAILURE": {
      return {
        ...state,
        readyToSubmit: false,
        errors: {
          ["submit"]: action.payload.error,
        },
      };
    }
    default: {
      return state;
    }
  }
};
