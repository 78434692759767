import { gql } from "@apollo/client";

export const GET_REWARDS_QUERY = gql`
  query adminGetRewards($brandId: Int, $take: Int!, $skip: Int!) {
    adminGetRewards(brandId: $brandId, take: $take, skip: $skip) {
      id
      name
      minAmount
      maxAmount
    }
  }
`;
