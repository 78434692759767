import { gql } from "@apollo/client";

export const CREATE_BRAND_MUTATION = gql`
  mutation adminCreateBrand($name: String!, $imageInB64: String!) {
    adminCreateBrand(name: $name, imageInB64: $imageInB64) {
      id
      name
      imageUrl
    }
  }
`;
