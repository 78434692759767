import { gql } from "@apollo/client";

export const VOID_MATCHUP_MUTATION = gql`
    mutation AdminVoidMatchup($matchupId: Int!) {
        adminVoidMatchup(matchupId: $matchupId) {
            id
            status
            name
        }
    }
`