export const white = "#FFFFFF";
export const white2 = "#F2F2F2";
export const white3 = "#F5F3F3";

export const black1 = "#000000";
export const black2 = "#121212";
export const black3 = "#2D283E";
export const black4 = "#D0D0D0";

export const translucent950 = "rgba(255, 255, 255, 0.95)";
export const translucent880 = "rgba(255, 255, 255, 0.88)";
export const translucent850 = "rgba(255, 255, 255, 0.85)";
export const translucent650 = "rgba(255, 255, 255, 0.65)";
export const translucent400 = "rgba(255, 255, 255, 0.4)";
export const translucent150 = "rgba(255, 255, 255, 0.15)";

export const darkTranslucent20 = "rgba(0, 0, 0, 0.02)";
export const darkTranslucent30 = "rgba(0, 0, 0, 0.03)";
export const darkTranslucent100 = "rgba(0, 0, 0, 0.1)";
export const darkTranslucent200 = "rgba(0, 0, 0, 0.2)";
export const darkTranslucent250 = "rgba(0, 0, 0, 0.25)";
export const darkTranslucent300 = "rgba(0, 0, 0, 0.3)";
export const darkTranslucent500 = "rgba(0, 0, 0, 0.5)";
export const darkTranslucent650 = "rgba(0, 0, 0, 0.65)";
export const darkTranslucent750 = "rgba(0, 0, 0, 0.75)";
export const darkTranslucent950 = "rgba(0, 0, 0, 0.95)";

// --blue: #4d8ff8;
// --green: #5cfab3;
// --pink: ##ff5edb;
// --yellow: #f7fb80;
export const yellow = "#f7fb80";
export const softYellow = "#f7fb8080";
export const green = "#5cfab3";
export const softGreen = "#5cfab340";
export const pink = "#ff5edb";
export const blue = "#4d8ff8";

export const primary = yellow;
export const subtle = softYellow;

export const background = black1;
export const success = "#68E26D";
export const error = "#FF0033";
export const softError = "#FF003365";
