import * as Styles from "../../styles";
import { style as s } from "typestyle";

export const component = s({
  display: "flex",
  justifyContent: "center",
  width: "auto",
  border: `1px solid black`,
  borderRadius: 4,
  padding: "12px 20px",
  backgroundColor: Styles.colors.softGreen,
  cursor: "pointer",
  $nest: {
    "&:hover": {
      backgroundColor: Styles.colors.green,
    },
  },
});

export const disabled = s({
  cursor: "not-allowed",
  backgroundColor: Styles.colors.darkTranslucent100,
  color: Styles.text.colors.subtle,
  $nest: {
    "&:hover": {
      backgroundColor: Styles.colors.darkTranslucent100,
      cursor: "not-allowed",
    },
  },
});
