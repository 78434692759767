import * as Models from "../../models";
import { ContestActions } from "../actions";

export type ContestsStoreContestTemplate = Omit<
  Models.Contests.ContestTemplate,
  "buckets"
> & {
  buckets: ContestsStoreBucket[];
};

export type ContestsStoreBucket = Models.Contests.Bucket & {
  matchupIds: number[];
};

export interface ContestsStore {
  contestTemplates: Record<number, ContestsStoreContestTemplate> | null;
  matchups: Record<number, Models.Contests.Matchup> | null;
  matchupOptions: Record<number, Models.Contests.MatchupOption> | null;
}

export const initialState: ContestsStore = {
  contestTemplates: null,
  matchups: null,
  matchupOptions: null,
};

export const contests = (
  state: ContestsStore = initialState,
  action: ContestActions.Actions,
): ContestsStore => {
  switch (action.type) {
    case ContestActions.ActionTypes.GET_CONTEST_TEMPLATES_SUCCESS: {
      const newTemplateDict: Record<string, ContestsStoreContestTemplate> =
        state.contestTemplates ? { ...state.contestTemplates } : {};
      const newMatchupDict: Record<string, Models.Contests.Matchup> =
        state.matchups ? { ...state.matchups } : {};
      action.payload.contestTemplates.forEach((template) => {
        newTemplateDict[template.id] = {
          ...template,
          buckets: template.buckets.map((bucket) => ({
            ...bucket,
            matchupIds: bucket.matchups.map((matchup) => matchup.id),
          })),
        };
        template.buckets.forEach((bucket) => {
          bucket.matchups.forEach((matchup) => {
            newMatchupDict[matchup.id] = matchup;
          });
        });
      });
      return {
        ...state,
        contestTemplates: newTemplateDict,
        matchups: newMatchupDict,
      };
    }
    case ContestActions.ActionTypes.GET_MATCHUPS_SUCCESS: {
      const newMatchupDict: Record<string, Models.Contests.Matchup> =
        state.matchups ? { ...state.matchups } : {};

      action.payload.matchups.forEach((matchup) => {
        newMatchupDict[matchup.id] = matchup;
      });

      return {
        ...state,
        matchups: newMatchupDict,
      };
    }
    case ContestActions.ActionTypes.GET_MATCHUP_OPTIONS_SUCCESS: {
      const newMatchupOptionDict: Record<
        string,
        Models.Contests.MatchupOption
      > = state.matchupOptions ? { ...state.matchupOptions } : {};
      action.payload.matchupOptions.forEach((opt) => {
        newMatchupOptionDict[opt.id] = opt;
      });
      return {
        ...state,
        matchupOptions: newMatchupOptionDict,
      };
    }
    case ContestActions.ActionTypes.CREATE_MATCHUP_OPTION_SUCCESS: {
      const newMatchupOptionDict: Record<
        string,
        Models.Contests.MatchupOption
      > = state.matchupOptions ? { ...state.matchupOptions } : {};
      newMatchupOptionDict[action.payload.id] = action.payload;
      return {
        ...state,
        matchupOptions: newMatchupOptionDict,
      };
    }
    case ContestActions.ActionTypes.CREATE_MATCHUP_SUCCESS: {
      const newMatchupsDict: Record<string, Models.Contests.Matchup> =
        state.matchups ? { ...state.matchups } : {};
      newMatchupsDict[action.payload.id] = action.payload;
      return {
        ...state,
        matchups: newMatchupsDict,
      };
    }
    default:
      return state;
  }
};
