import * as Styles from "../../styles";
import { style as s } from "typestyle";

export const component = s({
  padding: "8px 12px",
  border: "1px solid black",
  borderRadius: 4,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  width: "fit-content",
  margin: "auto",
  marginBottom: 24,
});

export const label = s({
  fontSize: 16,
  fontWeight: 600,
});

export const imageContainer = s({
  width: 36,
  height: 36,
});

export const image = s({
  width: "100%",
});

export const initialsContainer = s({
  borderRadius: "50%",
  padding: 6,
  marginBottom: 4,
});

export const initials = s({
  fontSize: 12,
  width: 24,
  height: 24,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
