import { gql } from "@apollo/client";

export const GET_MATCHUP_OPTIONS_QUERY = gql`
  query adminMatchupOptions($take: Int!, $skip: Int!) {
    adminMatchupOptions(take: $take, skip: $skip) {
      id
      name
      defaultMatchupOptionVisualizationId
      defaultMatchupOptionVisualization {
        id
        description
        displayName
        initials
        initialsBackgroundColor
        initialsTextColor
        imageUrl
      }
    }
  }
`;
