import React from "react";
import * as style from "./CreateMatchupOptions.style";
import { useApolloClient, useMutation } from "@apollo/client";
import { HexAlphaColorPicker, HexColorInput } from "react-colorful";
import { Mutation } from "../../../gql";
import { connect, useDispatch } from "react-redux";
import { ContestActions } from "../../../store/actions";
import * as Models from "../../../models";
import { RootState } from "../../../store/reducers";
import * as Components from "../../../components";
import {
  createMatchupOptionsReducer,
  initialCreateMatchupOptionsState,
} from "./CreateMatchupOptions.reducer";

interface ComponentProps {
  matchupOptions: Record<number, Models.Contests.MatchupOption> | null;
}

export const CreateMatchupOptionsInternal: React.FC<ComponentProps> = ({
  matchupOptions,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();

  /**
   * Values
   */
  const [state, localDispatch] = React.useReducer(
    createMatchupOptionsReducer,
    initialCreateMatchupOptionsState,
  );

  /** Image  */
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  React.useEffect(() => {
    if (selectedFile != null) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(selectedFile);
      fileReader.onload = () => {
        localDispatch({
          type: "UPDATE_FIELD",
          payload: {
            field: "newImageInB64",
            value: fileReader!.result!.toString(),
          },
        });
      };
    }
  }, [selectedFile]);

  const [createMatchupOptionMutation, createMatchupOptionMutationStatus] =
    useMutation(Mutation.CREATE_MATCHUP_OPTION_MUTATION);

  React.useEffect(() => {
    if (state.readyToSubmit) {
      createMatchupOptionMutation({
        variables: {
          name: state.name,
          visualization: {
            displayName: state.displayName,
            description: state.description,
            initials: state.initials,
            initialsBackgroundColor: state.initialsBackgroundColor,
            initialsTextColor: state.initialsTextColor,
            newImageInB64: state.newImageInB64,
          },
        },
      })
        .then((res) => {
          if (res?.data?.adminCreateMatchupOption) {
            localDispatch({ type: "SUBMIT_SUCCESS" });
            dispatch(
              ContestActions.createMatchupOptionSuccess(
                res.data.adminCreateMatchupOption,
              ),
            );
            setSelectedFile(null);
          }
        })
        .catch((e) => {
          localDispatch({
            type: "SUBMIT_FAILURE",
            payload: {
              error: e?.graphQLErrors?.length ? e.graphQLErrors[0].message : "",
            },
          });
        });
    }
  }, [state.readyToSubmit]);

  return (
    <div className={style.main}>
      <div className={style.title} style={{ fontSize: 32 }}>
        Create Matchup Option
      </div>
      <Components.TextInput
        className={style.textInput}
        value={state.name}
        onChange={(e) =>
          localDispatch({
            type: "UPDATE_FIELD",
            payload: {
              field: "name",
              value: e,
            },
          })
        }
        label="Name"
        error={state.errors["name"]}
        autocomplete="off"
      />
      <div className={style.subtitle}>Default Visualization</div>
      <div className={style.description}>
        None of these fields are required. If all are left empty the Matchup
        Option "Name" will be used. If included, image is first priority, then
        initials (background and text colors are required), then display name.
      </div>
      <Components.TextInput
        className={style.textInput}
        value={state.displayName}
        onChange={(e) =>
          localDispatch({
            type: "UPDATE_FIELD",
            payload: {
              field: "displayName",
              value: e,
            },
          })
        }
        label="Display Name"
        error={state.errors["displayName"]}
        autocomplete="off"
      />
      <Components.TextInput
        className={style.textInput}
        value={state.description}
        onChange={(e) =>
          localDispatch({
            type: "UPDATE_FIELD",
            payload: {
              field: "description",
              value: e,
            },
          })
        }
        label="Description"
        error={state.errors["description"]}
        autocomplete="off"
      />
      <Components.TextInput
        className={style.textInput}
        value={state.initials}
        onChange={(e) =>
          localDispatch({
            type: "UPDATE_FIELD",
            payload: {
              field: "initials",
              value: e,
            },
          })
        }
        label="Initials"
        error={state.errors["initials"]}
        autocomplete="off"
      />
      <div className={style.colorRow}>
        <HexAlphaColorPicker
          className={style.colorBox}
          color={state.initialsBackgroundColor}
          onChange={(e) =>
            localDispatch({
              type: "UPDATE_FIELD",
              payload: {
                field: "initialsBackgroundColor",
                value: e,
              },
            })
          }
          prefix="#"
        />
        <HexColorInput
          color={state.initialsBackgroundColor}
          onChange={(e) =>
            localDispatch({
              type: "UPDATE_FIELD",
              payload: {
                field: "initialsBackgroundColor",
                value: e,
              },
            })
          }
          alpha
        />
        <div className={style.label}>Initials Background Color</div>
        {state.errors["initialsBackgroundColor"] && (
          <div className={style.error}>
            {state.errors["initialsBackgroundColor"]}
          </div>
        )}
      </div>
      <div className={style.colorRow}>
        <HexAlphaColorPicker
          className={style.colorBox}
          color={state.initialsTextColor}
          onChange={(e) =>
            localDispatch({
              type: "UPDATE_FIELD",
              payload: {
                field: "initialsTextColor",
                value: e,
              },
            })
          }
          prefix="#"
        />
        <HexColorInput
          color={state.initialsTextColor}
          onChange={(e) =>
            localDispatch({
              type: "UPDATE_FIELD",
              payload: {
                field: "initialsTextColor",
                value: e,
              },
            })
          }
          alpha
        />
        <div className={style.label}>Initials Text Color</div>

        {state.errors["initialsTextColor"] && (
          <div className={style.error}>{state.errors["initialsTextColor"]}</div>
        )}
      </div>
      <div className={style.input}>
        <input
          type="file"
          name="image"
          onChange={(e) => setSelectedFile(e!.currentTarget!.files![0])}
          accept="image/jpg, image/jpeg, image/png, image/svg, image/gif"
        />
        {state.errors["newImageInB64"] && (
          <div className={style.error}>{state.errors["newImageInB64"]}</div>
        )}
      </div>
      <div className={style.imageContainer}>
        <img
          src={state.newImageInB64}
          alt="prop image"
          className={style.image}
        />
      </div>
      <div
        onClick={() => {
          setSelectedFile(null);
          localDispatch({
            type: "UPDATE_FIELD",
            payload: {
              field: "newImageInB64",
              value: "",
            },
          });
        }}
        className={style.removeImageButton}
      >
        Remove image
      </div>
      <Components.Button
        className={style.button}
        onClick={() => localDispatch({ type: "SUBMIT" })}
        label="Submit"
        inProgress={state.readyToSubmit}
      />
      {state.errors["submit"] && (
        <div className={style.error}>{state.errors["submit"]}</div>
      )}
    </div>
  );
};

export const CreateMatchupOptions = connect((state: RootState) => ({
  matchupOptions: state.contests.matchupOptions,
}))(CreateMatchupOptionsInternal);
