import * as Models from "../../models";
import { RewardActions } from "../actions";

export type RewardsStoreBrand = Omit<Models.Rewards.Brand, "rewards"> & {
  rewards: number[];
};

export interface RewardsStore {
  brands: {
    items: Record<string, RewardsStoreBrand> | null;
  };
  rewards: {
    items: Record<string, Models.Rewards.Reward> | null;
  };
}

export const initialState: RewardsStore = {
  brands: {
    items: null,
  },
  rewards: {
    items: null,
  },
};

export const rewards = (
  state: RewardsStore = initialState,
  action: RewardActions.Actions,
): RewardsStore => {
  switch (action.type) {
    case RewardActions.ActionTypes.GET_BRANDS_SUCCESS: {
      const newBrandDict: Record<string, RewardsStoreBrand> = state.brands.items
        ? { ...state.brands.items }
        : {};
      const newRewardDict: Record<string, Models.Rewards.Reward> = state.rewards
        .items
        ? { ...state.rewards.items }
        : {};
      action.payload.forEach((brand: Models.Rewards.Brand) => {
        newBrandDict[brand.id] = {
          ...brand,
          rewards: brand.rewards.map((reward) => reward.id),
        };
        brand.rewards.forEach((reward) => {
          newRewardDict[reward.id] = reward;
        });
      });
      return {
        ...state,
        brands: {
          items: newBrandDict,
        },
        rewards: {
          items: newRewardDict,
        },
      };
    }
    case RewardActions.ActionTypes.GET_REWARDS_SUCCESS: {
      const newDict: Record<string, Models.Rewards.Reward> = state.rewards.items
        ? { ...state.rewards.items }
        : {};
      action.payload.forEach((reward: Models.Rewards.Reward) => {
        newDict[reward.id] = reward;
      });
      return {
        ...state,
        rewards: {
          items: newDict,
        },
      };
    }
    case RewardActions.ActionTypes.UPDATE_BRAND_SUCCESS: {
      const newDict: Record<string, RewardsStoreBrand> = state.brands.items
        ? { ...state.brands.items }
        : {};
      newDict[action.payload.id] = {
        ...newDict[action.payload.id],
        ...action.payload,
        rewards: newDict[action.payload.id]?.rewards,
      };
      return {
        ...state,
        brands: {
          ...state.brands,
          items: newDict,
        },
      };
    }
    default:
      return state;
  }
};
