import { gql } from "@apollo/client";

export const UPDATE_PRIZE_MUTATION = gql`
    mutation adminUpdatePrize($prizeId: Int!, $delivered: Boolean!) {
        adminUpdatePrize(prizeId: $prizeId, delivered: $delivered) {
            id
            userId
            rewardId
            contestId
            rewardAmount
            deliveryEmail
            delivered
            updatedAtUtc
            adminUpdatedBy
        }
    }
`