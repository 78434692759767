import * as Styles from "../../styles";
import { style as s } from "typestyle";

export const header = s({
  backgroundColor: "#F5F5F5",
  position: "fixed",
  width: "200px",
  height: "100vh",
  padding: "12px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
});

export const logoContainer = s({
  padding: "8px 0px 0px 0px"
});

export const route = s({
  padding: '12px 0px 8px 8px',
  color: Styles.colors.black2,
  textTransform: "uppercase",
  textDecoration: "none",
  $nest: {
    "&:hover": {
      backgroundColor: Styles.colors.white,
      borderRadius: "8px",
      cursor: "pointer",
    },
  },
});

export const activeRoute = s({
  backgroundColor: Styles.colors.white,
  borderRadius: "8px",
  $nest: {
    "&:hover": {
      cursor: "default",
    },
  },
});
