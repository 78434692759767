import * as Models from "../../models";
import * as ActionModels from "./ActionCreator";

export enum ActionTypes {
  /**
   * REWARDS
   */
  GET_BRANDS_SUCCESS = "REWARDS/GET_BRANDS_SUCCESS",
  UPDATE_BRAND_SUCCESS = "REWARDS/UPDATE_BRAND_SUCCESS",
  GET_REWARDS_SUCCESS = "REWARDS/GET_REWARDS_SUCCESS",
}

export const GetBrandsSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.GET_BRANDS_SUCCESS,
  Models.Rewards.Brand[]
> = ActionModels.createActionCreator(ActionTypes.GET_BRANDS_SUCCESS);

export const UpdateBrandSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.UPDATE_BRAND_SUCCESS,
  Partial<Models.Rewards.Brand> & { id: number }
> = ActionModels.createActionCreator(ActionTypes.UPDATE_BRAND_SUCCESS);

export const GetRewardsSucces: ActionModels.ActionCreator<
  typeof ActionTypes.GET_REWARDS_SUCCESS,
  Models.Rewards.Reward[]
> = ActionModels.createActionCreator(ActionTypes.GET_REWARDS_SUCCESS);

export type Actions = ReturnType<
  typeof GetBrandsSuccess | typeof UpdateBrandSuccess | typeof GetRewardsSucces
>;
