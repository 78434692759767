import * as Styles from "../../styles";
import { style as s } from "typestyle";

export const component = s({});

export const table = s({
  border: "1px solid black",
  margin: "32px 30px 32px 0px",
});

export const tableRow = s({
  width: "100%",
  display: "flex",
  alignItems: "center",
});

export const row = s({
  display: "flex",
  $nest: {
    "&:nth-child(even)": {
      backgroundColor: Styles.colors.darkTranslucent100,
    },
  },
});

export const rowItem = s({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flex: 1,
  padding: "4px 0px",
});

export const imageContainer = s({
  height: "40px",
});

export const image = s({
  height: "100%",
});

export const selectedRow = s({
  border: "1px solid yellow",
});

export const subTitle = s({
  textAlign: "center",
  fontSize: 32,
  fontWeight: 500,
});

export const selectedUser = s({
  border: "1px solid black",
});

export const textInput = s({ width: "100%" });

export const error = s({
  color: Styles.colors.error,
  fontSize: 14,
});

export const input = s({
  display: "flex",
  gap: 12,
  alignItems: "center",
});
