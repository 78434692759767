import React from "react";
import * as style from "./ViewDeliveredPrizes.style";
import { useApolloClient } from "@apollo/client";
import * as Models from '../../../models';
import { Query } from "../../../gql";
import { toShortDateAndTimeUTC } from "../../../utility/date";
import { colors } from "../../../styles";
import { Icon } from "../../../components";
import { toast } from "react-toastify";

export const ViewDeliveredPrizes: React.FC = () => {
  const client = useApolloClient();
  const [prizes, setPrizes] = React.useState<Models.Rewards.Prize[]>([]);
  const [inProgress, setInProgress] = React.useState<boolean>(false);

  const getPrizes = (skip?: number) => {
    setInProgress(true);
    client
      .query({
        query: Query.GET_PRIZES_QUERY,
        variables: {
          delivered: true,
          deliveryEmailExists: true,
          skip: skip ?? 0,
          take: 20,
        },
      })
      .then((res) => {
        if (res?.data?.adminGetPrizes) {
          setInProgress(false);
          setPrizes(res?.data?.adminGetPrizes)
        } else {
          toast.error("Failed to fetch delivered Prizes")
        }
      })
      .catch((e) => {
        setInProgress(false);
        toast.error("Failed to fetch delivered Prizes")
      })
  };
  React.useEffect(() => {
    getPrizes();
  }, []);

  return (
    <div className={style.component}>
      {(!!prizes.length && !inProgress) ? (
        <div className={style.table}>
          <div className={style.row} style={{backgroundColor: colors.softYellow}}>
              <div className={style.rowItem}>Contest Name</div>
              <div className={style.rowItem}>Brand</div>
              <div className={style.rowItem}>Reward Amount</div>
              <div className={style.rowItem}>Last Updated</div>
              <div className={style.rowItem}>User</div>
              <div className={style.rowItem}>Claimed</div>
              <div className={style.rowItem}>Delivered</div>
          </div>
          {prizes.map((prize) => {
            return (
              prize && (
                <div className={style.row} key={prize.id}>
                  <div className={style.rowItem}>{prize.contest?.contestTemplate?.name}</div>
                  <div className={style.rowItem}>{prize.reward?.brand?.name}</div>
                  <div className={style.rowItem}>${prize.rewardAmount}</div>
                  <div className={style.rowItem}>{toShortDateAndTimeUTC(new Date(prize.updatedAtUtc))}</div>
                  <div className={style.rowItem}>{prize.user?.displayName}</div>
                  {!!prize.deliveryEmail ? (
                  <div className={style.rowItem}>{prize.deliveryEmail}</div>
                  ) : (
                  <div className={style.rowItem}>Not Claimed</div>
                  )}
                  {!!prize.delivered ? (
                  <div className={style.rowItem}>Delivered</div>
                  ) : (
                  <div className={style.rowItem}>Not Delivered</div>
                  )}
                </div>
              )
            );
          })}

        </div>
      ) : (
        <Icon.Spinner size={24} />
      )}
    </div>
  );
};
