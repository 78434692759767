import React from "react";
import * as style from "./ViewContestTemplates.style";
import { useApolloClient } from "@apollo/client";
import { Query } from "../../../gql";
import { connect, useDispatch } from "react-redux";
import { ContestActions } from "../../../store/actions";
import { RootState } from "../../../store/reducers";
import { ContestsStoreContestTemplate } from "../../../store/reducers/contests";

interface ComponentProps {
  contestTemplates: Record<number, ContestsStoreContestTemplate> | null;
}

export const ViewContestTemplatesInternal: React.FC<ComponentProps> = ({
  contestTemplates,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();

  const templatesArray = React.useMemo(() => {
    return !!contestTemplates ? Object.values(contestTemplates) : [];
  }, [contestTemplates]);

  const getContestTemplates = () => {
    client
      .query({
        query: Query.GET_CONTEST_TEMPLATES_QUERY,
        variables: {
          take: 20,
          skip: 0,
        },
      })
      .then((res) => {
        if (res?.data?.adminContestTemplates?.length) {
          dispatch(
            ContestActions.getContestTemplatesSuccess({
              contestTemplates: res.data.adminContestTemplates,
            }),
          );
        }
      })
      .catch((res) => {
        console.log("failed", res);
      });
  };

  React.useEffect(() => {
    getContestTemplates();
  }, []);

  return (
    <div className={style.main}>
      {templatesArray.map((template) => (
        <div>{template.name}</div>
      ))}
    </div>
  );
};

export const ViewContestTemplates = connect((state: RootState) => ({
  contestTemplates: state.contests.contestTemplates,
}))(ViewContestTemplatesInternal);
