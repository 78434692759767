import { gql } from "@apollo/client";

export const GET_CONTEST_TEMPLATES_QUERY = gql`
  query adminContestTemplates($take: Int!, $skip: Int!) {
    adminContestTemplates(take: $take, skip: $skip) {
      id
      status
      name
      description
      viewableStartsAtUtc
      availableStartsAtUtc
      availableEndsAtUtc
      minContestants
      maxContestants
      maxContests
      tokenEntry
      freeEntry
      minTokenEntry
      maxTokenEntry
      buckets {
        id
        name
        description
        selectionWindowStartsAtUtc
        selectionWindowEndsAtUtc
        minSelection
        maxSelection
        matchups {
          id
          name
          description
          eventStartTime
          sport
          matchupOptions {
            matchupOptionVisualization {
              id
              description
              displayName
              initials
              initialsBackgroundColor
              initialsTextColor
              imageUrl
            }
            matchupOption {
              id
              name
            }
          }
        }
      }
    }
  }
`;
