import { gql } from "@apollo/client";

export const CREATE_REWARD_MUTATION = gql`
  mutation adminCreateReward(
    $brandId: Int!
    $name: String!
    $minAmount: Int!
    $maxAmount: Int!
  ) {
    adminCreateReward(
      brandId: $brandId
      name: $name
      minAmount: $minAmount
      maxAmount: $maxAmount
    ) {
      brandId
      name
      minAmount
      maxAmount
    }
  }
`;
