import * as React from "react";
import * as style from "./Header.style";
import {
  SignedIn,
  SignedOut,
  SignInButton,
  UserButton,
  useUser,
  useAuth,
} from "@clerk/clerk-react";
import { Link, useLocation } from "react-router-dom";
import logo from '../../../assets/logo-with-name.png';

export const Header: React.FC = () => {
  const { pathname } = useLocation();

  return (
    <div className={style.header}>
      <div >
        <SignedOut>
          <SignInButton />
        </SignedOut>
        <SignedIn>
          <UserButton />
          <JwtTracker />
        </SignedIn>
      </div>
      <div className={style.logoContainer}>
        <img src={logo} width={140} height={40}/>
      </div>
      <Link
        to="/"
        className={`${style.route} ${
          pathname == "/" ? style.activeRoute : ""
        }`}
      >
        Home
      </Link>
      <Link
        to="/users"
        className={`${style.route} ${
          pathname == "/users" ? style.activeRoute : ""
        }`}
      >
        Users
      </Link>
      <Link
        to="/contests"
        className={`${style.route} ${
          pathname == "/contests" ? style.activeRoute : ""
        }`}
      >
        Contests
      </Link>
      <Link
        to="/rewards"
        className={`${style.route} ${
          pathname == "/rewards" ? style.activeRoute : ""
        }`}
      >
        Rewards
      </Link>
      <Link
        to="/prizes"
        className={`${style.route} ${
          pathname == "/prizes" ? style.activeRoute : ""
        }`}
      >
        Prizes
      </Link>
    </div>
  );
};

export const JwtTracker: React.FC = () => {
  const { getToken } = useAuth();
  const { user } = useUser();
  React.useEffect(() => {
    if (user) {
      getToken({ template: "jwt_with_email" }).then((res) => {
        console.log("JWT Token::\n", res);
        if (res && !!localStorage) {
          localStorage.setItem("userJwt", res);
        }
      });
    }
  }, [user, localStorage]);

  return null;
};
