import React from "react";
import * as style from "./ViewClaimedPrizes.style";
import * as Models from '../../../models';
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation, Query } from "../../../gql";
import * as Components from "../../../components";
import { Icon } from "../../../components";
import { toast } from "react-toastify";
import { toShortDateAndTimeUTC } from "../../../utility/date";
import { colors } from "../../../styles";

export const ViewClaimedPrizes: React.FC = () => {
  const client = useApolloClient();
  const [prizes, setPrizes] = React.useState<Models.Rewards.Prize[]>([]);
  const [selectedPrize, setSelectedPrize] = React.useState<Models.Rewards.Prize | null>(null);
  const [inProgress, setInProgress] = React.useState<boolean>(false);

  const getPrizes = () => {
    setInProgress(true)
    client
      .query({
        query: Query.GET_PRIZES_QUERY,
        fetchPolicy: 'no-cache',
        variables: {
          delivered: false,
          deliveryEmailExists: true,
          skip: 0,
          take: 20,
        },
      })
      .then((res) => {
        if (res?.data?.adminGetPrizes) {
          setInProgress(false)
          setPrizes(res?.data?.adminGetPrizes)
        } else {
          toast.error("Failed to fetch delivered Prizes")
        }
      })
      .catch((res) => {
        setInProgress(false)
        toast.error("Failed to fetch claimed prizes");
      });
  };

  //TODO will need to be refactored when storing this data in redux
  React.useEffect(() => {
    getPrizes();
  }, [selectedPrize]);

  const [updatePrizeMutation, updatePrizeMutationStatus] = useMutation(Mutation.UPDATE_PRIZE_MUTATION);
  const updatePrize = (prizeId: number) => {
    setInProgress(true);
    updatePrizeMutation({
      variables: {
        prizeId,
        delivered: true
      }
    }).then(res => {
      if (res?.data?.adminUpdatePrize) {
          setInProgress(false)
          setSelectedPrize(null)
          toast.success("Success updating Prize as delivered")
      } else {
        toast.error("Failed to update Prize")
      }
    }).catch(e => {
      setInProgress(false)
      setSelectedPrize(null)
      toast.error("Failed to update Prize")
    })
  }

 return (
    <div className={style.component}>

      {!!prizes.length && !inProgress ? (
        <div className={style.table}>
          <div className={style.row} style={{backgroundColor: colors.softYellow}}>
            <div className={style.rowItem}>Contest Name</div>
            <div className={style.rowItem}>Brand</div>
            <div className={style.rowItem}>Reward Amount</div>
            <div className={style.rowItem}>Last Updated</div>
            <div className={style.rowItem}>User</div>
            <div className={style.rowItem}>Claimed</div>
            <div className={style.rowItem}>Deliver</div>
          </div>
          {prizes.map((prize) => {
            return (
              ((!!prize && !!prize.deliveryEmail) && (
                <div className={style.row} key={prize.id}>
                  <div className={style.rowItem}>{prize.contest?.contestTemplate?.name}</div>
                  <div className={style.rowItem}>{prize.reward?.brand?.name}</div>
                  <div className={style.rowItem}>${prize.rewardAmount}</div>
                  <div className={style.rowItem}>{toShortDateAndTimeUTC(new Date(prize.updatedAtUtc))}</div>
                  <div className={style.rowItem}>{prize.user?.displayName}</div>
                  {!!prize.deliveryEmail ? (
                    <div className={style.rowItem}>{prize.deliveryEmail}</div>
                  ) : (
                    <div className={style.rowItem}>Not Claimed</div>
                  )}
                  <div className={style.rowItem}>
                    <Components.Button 
                      label="Deliver"
                      onClick={() => {
                        setSelectedPrize(prize);
                      }}
                    />
                  </div>
                </div>
              ))
            );
          })}
        </div>
      ) : (
        <Icon.Spinner size={24} />
      )}

      {!!selectedPrize && (
        <div className={style.modalContainer}>
          <div style={{fontSize: "20px", fontWeight: "bold"}} className={style.modalContainerItems}>{selectedPrize.contest?.contestTemplate?.name}</div>
          <div className={style.modalContainerItems}>Reward Amount: ${selectedPrize.rewardAmount}</div>
          <div className={style.modalContainerItems}> User display name: {selectedPrize.user?.displayName}</div>
          <div className={style.modalContainerItems}> User Email: {selectedPrize.deliveryEmail}</div>
          <div className={style.modalContainerItems}>Are you sure you want to mark this Prize as delivered?</div>
          <Components.Button 
              className={style.modalContainerItems} 
              onClick={() => {
                  if (!!selectedPrize) {
                      updatePrize(selectedPrize.id);
                  }
              }}
              label="Confirm"
          />
          <Components.Button 
            label="Cancel" 
            className={style.redButton} 
            onClick={() => {
              setSelectedPrize(null);
            }}
          />
        </div>
      )}
    </div>
 );

};
