import React from "react";
import * as style from "./ViewUnclaimedPrizes.style";
import { useApolloClient } from "@apollo/client";
import * as Models from '../../../models';
import { Query } from "../../../gql";
import { toShortDateAndTimeUTC } from "../../../utility/date";
import { colors } from "../../../styles";
import { Icon } from "../../../components";
import { toast } from "react-toastify";

export const ViewUnclaimedPrizes: React.FC = () => {
    const client = useApolloClient();
    const [unclaimedPrizes, setUnclaimedPrizes] = React.useState<Models.Rewards.Prize[]>([]);
    const [inProgress, setInProgress] = React.useState<boolean>(false);

    const getUnclaimedPrizes = (skip?: number) => {
      setInProgress(true);
      client
        .query({
          query: Query.GET_PRIZES_QUERY,
          fetchPolicy: 'no-cache',
          variables: {
            delivered: false,
            deliveryEmailExists: false,
            skip: skip ?? 0,
            take: 20,
          },
        })
        .then((res) => {
          if (res?.data?.adminGetPrizes) {
            setInProgress(false);
            setUnclaimedPrizes(res?.data?.adminGetPrizes)
          } else {
            toast.error("Failed to fetch unclaimed prizes");
          }
        })
        .catch((e) => {
          setInProgress(false);
          toast.error("Failed to fetch unclaimed prizes");
        })
    };
    React.useEffect(() => {
      getUnclaimedPrizes();
    }, []);

  return (
    <div className={style.component}>
      {(!!unclaimedPrizes.length && !inProgress) ? (
        <div className={style.table}>
          <div className={style.row} style={{backgroundColor: colors.softYellow}}>
              <div className={style.rowItem}>Contest Name</div>
              <div className={style.rowItem}>Brand</div>
              <div className={style.rowItem}>Reward Amount</div>
              <div className={style.rowItem}>Last Updated</div>
              <div className={style.rowItem}>User</div>
              {/* <div className={style.rowItem}>Last Updated At</div> */}
              <div className={style.rowItem}>Claimed</div>
              <div className={style.rowItem}>Delivered</div>
          </div>
          {unclaimedPrizes.map((prize) => {
            return (
              (prize && !prize.deliveryEmail) && (
                <div className={style.row} key={prize.id}>
                  <div className={style.rowItem}>{prize.contest?.contestTemplate?.name}</div>
                  <div className={style.rowItem}>{prize.reward?.brand?.name}</div>
                  <div className={style.rowItem}>${prize.rewardAmount}</div>
                  <div className={style.rowItem}>{toShortDateAndTimeUTC(new Date(prize.updatedAtUtc))}</div>
                  <div className={style.rowItem}>{prize.user?.displayName}</div>
                  {!!prize.deliveryEmail ? (
                    <div className={style.rowItem}>{prize.deliveryEmail}</div>
                  ) : (
                    <div className={style.rowItem}>Not Claimed</div>
                  )}
                  {!!prize.delivered ? (
                    <div className={style.rowItem}>Delivered</div>
                  ) : (
                    <div className={style.rowItem}>Not Delivered</div>
                  )}
                </div>
              )
            );
          })}
        </div>
      ): (
        <Icon.Spinner size={24} />
      )}
    </div>
  );
};
