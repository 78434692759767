import { validation } from "../../../utility";

/**
 * State
 */

export interface CreateMatchupOptionsState {
  name: string;
  displayName?: string;
  description?: string;
  initials?: string;
  initialsBackgroundColor?: string;
  initialsTextColor?: string;
  imageUrl?: string;
  newImageInB64?: string;
  errors: Record<string, string>;
  readyToSubmit: boolean;
}

export const initialCreateMatchupOptionsState: CreateMatchupOptionsState = {
  name: "",
  displayName: "",
  description: "",
  initials: "",
  initialsBackgroundColor: "",
  initialsTextColor: "",
  imageUrl: "",
  newImageInB64: "",
  errors: {},
  readyToSubmit: false,
};

/**
 * Actions
 */

export interface UpdateFieldAction {
  type: "UPDATE_FIELD";
  payload: {
    field:
      | "name"
      | "description"
      | "displayName"
      | "initials"
      | "initialsBackgroundColor"
      | "initialsTextColor"
      | "imageUrl"
      | "newImageInB64";
    value: string;
  };
}

export interface SubmitAction {
  type: "SUBMIT";
}

export interface SubmitSuccessAction {
  type: "SUBMIT_SUCCESS";
}

export interface SubmitFailureAction {
  type: "SUBMIT_FAILURE";
  payload: {
    error: string;
  };
}

export type CreateMatchupOptionsActions =
  | UpdateFieldAction
  | SubmitAction
  | SubmitSuccessAction
  | SubmitFailureAction;

/**
 * Reducer
 */

export const createMatchupOptionsReducer = (
  state: CreateMatchupOptionsState,
  action: CreateMatchupOptionsActions,
) => {
  switch (action.type) {
    case "UPDATE_FIELD": {
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    }
    case "SUBMIT": {
      let readyToSubmit = true;
      const newErrors: Record<string, string> = {};
      if (!state.name) {
        newErrors["name"] = "Must enter a name";
        readyToSubmit = false;
      } else if (
        !!state.initials &&
        (!state.initialsBackgroundColor || !state.initialsTextColor)
      ) {
        newErrors["initials"] =
          "If initials are included, must also include background and text colors";
      } else if (
        !!state.initialsTextColor &&
        !validation.hexColorRegex.test(state.initialsTextColor)
      ) {
        newErrors["initialsTextColor"] =
          "Please enter or select a valid hex color";
        readyToSubmit = false;
      } else if (
        !!state.initialsBackgroundColor &&
        !validation.hexColorRegex.test(state.initialsBackgroundColor)
      ) {
        newErrors["initialsBackgroundColor"] =
          "Please enter or select a valid hex color";
        readyToSubmit = false;
      }
      return { ...state, errors: newErrors, readyToSubmit };
    }
    case "SUBMIT_SUCCESS": {
      return initialCreateMatchupOptionsState;
    }
    case "SUBMIT_FAILURE": {
      return {
        ...state,
        readyToSubmit: false,
        errors: {
          submit: action.payload.error,
        },
      };
    }
  }
};
