import { gql } from "@apollo/client";

export const CREATE_MATCHUP_OPTION_MUTATION = gql`
  mutation adminCreateMatchupOption(
    $name: String!
    $visualization: CreateMatchupOptionVisualizationArgs!
  ) {
    adminCreateMatchupOption(name: $name, visualization: $visualization) {
      id
      name
      defaultMatchupOptionVisualizationId
      defaultMatchupOptionVisualization {
        id
        description
        displayName
        initials
        initialsBackgroundColor
        initialsTextColor
        imageUrl
      }
    }
  }
`;
