import { combineReducers } from "redux";

import { users } from "./users";
import { contests } from "./contests";
import { rewards } from "./rewards";

export const rootReducer = combineReducers({
  users,
  contests,
  rewards,
});

export type RootState = ReturnType<typeof rootReducer>;
