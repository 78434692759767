import React from "react";
import * as style from "./CreateRewards.style";
import { classes } from "typestyle";
import * as Components from "../../../../components";
import { useMutation } from "@apollo/client";
import { Mutation } from "../../../../gql";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { RewardActions } from "../../../../store/actions";

interface ComponentProps {
  brandId: number;
}

export const CreateRewards: React.FC<ComponentProps> = ({ brandId }) => {
  const [name, setName] = React.useState<string>("");
  const [minAmount, setMinAmount] = React.useState<string>("");
  const [maxAmount, setMaxAmount] = React.useState<string>("");

  const [inProgress, setInProgress] = React.useState(false);
  const [submitError, setSubmitError] = React.useState<string>("");

  const [createRewardMutation, createRewardMutationData] = useMutation(
    Mutation.CREATE_REWARD_MUTATION,
  );

  const dispatch = useDispatch();

  const onSubmit = () => {
    if (!name || !minAmount || !maxAmount) {
      setSubmitError("Please include a name and min/max amount");
    } else if (minAmount > maxAmount) {
      setSubmitError("Min amount must be less than max amount");
    } else {
      setInProgress(true);
      setSubmitError("");
      createRewardMutation({
        variables: {
          brandId,
          name,
          minAmount: Number.parseInt(minAmount),
          maxAmount: Number.parseInt(maxAmount),
        },
      })
        .then((res) => {
          setInProgress(false);
          setName("");
          setMinAmount("");
          setMaxAmount("");
          toast.success("Reward Created");
          dispatch(
            RewardActions.GetRewardsSucces([res.data.adminCreateReward]),
          );
        })
        .catch((e) => {
          setInProgress(false);
          console.log(e);
        });
    }
  };

  return (
    <div className={style.component}>
      <Components.TextInput
        className={style.textInput}
        value={name}
        onChange={setName}
        label="Name"
        autocomplete="off"
      />
      <Components.TextInput
        className={style.textInput}
        value={minAmount}
        onChange={setMinAmount}
        label="Min Amount"
        inputType="number"
      />
      <Components.TextInput
        className={style.textInput}
        value={maxAmount}
        onChange={setMaxAmount}
        label="Max Amount"
        inputType="number"
      />
      <Components.Button
        className={style.button}
        onClick={onSubmit}
        label="Submit"
        inProgress={inProgress}
      />
      <div className={style.error}>{submitError}</div>
    </div>
  );
};
