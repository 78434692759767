import React from "react";
import * as style from "./Home.style";

export const Home = () => {
  return (
    <div className={style.main}>
      <div className={style.titleContainer}>
        <div className={style.title}>Shake Portal Home</div>
      </div>
    </div>
  );
};
